<template>
  <section class="contact text-center" id="contact">
    <div class="content">
      <h2 class="section-header">Skontaktuj się z nami</h2>
      <p>Masz pomysł na projekt? Chętnie odpowiemy na wszystkie pytania!</p>
      <div class="contact-us d-flex flex-wrap">
        <div class="flex-equal">
          <div class="card">
            Zadzwoń:
            <a href="tel:+48782120445">
              <font-awesome-icon icon="phone" class="icon"/>
              782 120 445
            </a>
          </div>

        </div>
        <div class="flex-equal">
          <div class="card">
            Napisz:
            <a href="mailto:kontakt@atpsolutions.pl">
              <font-awesome-icon icon="envelope" class="icon"/>
              kontakt@atpsolutions.pl
            </a>
          </div>

        </div>

      </div>
      <form class="contact-form card">
        <p class="contact-header">Formularz kontaktowy</p>
        <div class="d-flex flex-wrap">
          <div class="form-group flex-equal">
            <label class="label">Imię</label>
            <input v-model="firstname" class="form-control" name="name" type="text" placeholder="Imię..." required="">
          </div>
          <div class="form-group flex-equal">
            <label class="label">E-mail</label>
            <input v-model="email" class="form-control" name="name" type="email" placeholder="E-mail..." required="">
          </div>
        </div>
        <div class="form-group">
          <label class="label">Treść</label>
          <textarea v-model="content" class="form-control" name="name" placeholder="Treść wiadomości..." required=""></textarea>
        </div>
        <div class="mt-5">
          <a class="btn btn-blue" @click="onSubmit()">Wyślij wiadomość</a>
        </div>
      </form>

    </div>
  </section>
</template>

<script>
export default {
  components: {

  },
  data: function () {
    return {
      firstname: '',
      email: '',
      content: ''
    }
  },
  methods: {
    onSubmit: function () {
      console.log('submit');
      const valid = this.validationForm();
      if (valid) {
        this.$http.post('https://api.atpsolutions.pl/contact/form', {
          firstname: this.firstname,
          email: this.email,
          content: this.content
        }).then((response) => {
          console.log(response.data)
          this.$notify({
            group: 'notify',
            type: 'success',
            text: 'Wiadomość została wysłana. Dziękujemy!'
          })
        })
      }

    },
    validationForm: function() {
      if (this.firstname.length === 0) {
        this.$notify({ group: 'notify', text: 'Wpisz poprawne imię', type: 'warn' });

        return false;
      }

      if (this.email.length === 0) {
        this.$notify({ group: 'notify', text: 'Wpisz poprawny email', type: 'warn' });

        return false;
      }

      if (this.content.length === 0) {
        this.$notify({ group: 'notify', text: 'Wpisz treść', type: 'warn' });

        return false;
      }

      return true;
    }
  }
}
</script>
<style lang="scss" >

</style>
