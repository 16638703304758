<template>
  <div>
    <nav class="navbar">
      <div class="content">
        <a class="logo" href="/"><span class="bold">ATP</span> Solutions</a>
        <div>
          <a class="btn btn-blue btn-contact" href="tel:+48782120445">
            <font-awesome-icon icon="phone" class="icon"/>
            <span class="number">782 120 445</span>
          </a>
          <a class="btn btn-outline" href="#contact">Kontakt</a>
        </div>

<!--        <ul>-->
<!--          <li><a class="nav-item link-underlined">O nas</a></li>-->
<!--          <li><a class="nav-item">Usługi</a></li>-->
<!--          <li><a class="nav-item">Realizacje</a></li>-->
<!--          <li><a class="btn btn-outline">Kontakt</a></li>-->
<!--        </ul>-->
<!--        <Slide>-->
<!--          <a id="home" href="#">-->
<!--            <span>Home</span>-->
<!--          </a>-->
<!--        </Slide>-->
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  components: {

  }
}
</script>
<style lang="scss" >
@import "./../styles/variables.scss";

  .navbar {
    background: white;
    margin: 0;
    //box-shadow: 0 3px 6px 0 rgba(9,27,105,.25);
    box-shadow: 0 4px 8px rgb(3 30 138 / 5%);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 15px;
    z-index: 100;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .btn-contact {
      margin-right: 10px;
      .icon {
        margin-right: 10px;
      }
      @media (max-width: 767px) {
        position: fixed;
        top: unset;
        bottom: 30px;
        right: 10px;
        .icon {
          margin: 0;
        }
        .number {
          display: none;
        }
      }
    }
    .logo {
      display: flex;
      font-weight: 400;
      font-size: 26px;
      letter-spacing: 0.5px;
      color: $text-dark;
      text-decoration: none;
      .bold {
        font-weight: bold;
        color: $main;
        margin-right: 3px;
      }
    }
    ul {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 4;
      li {
        list-style: none;
        margin-right: 45px;
        .nav-item {
          color: $text-body;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          &:hover {
            font-weight: 600;
            color: $main;
          }
        }
      }

    }
    @media (max-width: 767px) {
      //height: 77px;
      //.content {
      //  display: inline;
      //}
      //.logo {
      //  float: right;
      //}
      ul li:not(:last-child) {
        display: none;
      }
    }
  }

.bm-burger-button {
  top: 25px;
  left: 25px;
  .bm-burger-bars {
    background-color: $main;
  }
  @media (min-width: 768px) {
    display: none;
  }
}

.bm-menu {
  background-color: $bg-dark;
}

</style>
