<template>
  <div id="app">
    <notifications group="notify" position="top right" />
    <Header></Header>
<!--    <a class="phone" href="tel:+48782120445">-->
<!--      <font-awesome-icon icon="phone" class="icon"/>-->
<!--      782 120 445-->
<!--    </a>-->
    <section class="main main-bg">
      <div class="content">
        <div class="main-screen">
          <div class="main-content">
            <h1 class="section-header">Dedykowane platformy internetowe</h1>
            <p class="main-description">
              Jesteśmy zespołem specjalistów z zakresu programowania aplikacji internetowych.
              Naszą główną specjalizacją są aplikacje webowe (oprogramowanie przeglądarkowe),
              jednocześnie zajmujemy się także tworzeniem dedykowanych stron internetowych.
            </p>
            <div class="my-5">
              <a class="btn btn-blue" href="#contact">Skontaktuj się z nami</a>
            </div>
          </div>
          <div class="main-image">
            <img alt="Strony internetowe" src="./assets/images/comp.svg">
          </div>
        </div>
        <div class="shape shape-square">
          <img alt="" src="./assets/images/shapes/square.png">
        </div>
        <div class="shape shape-1">
          <img alt="" src="./assets/images/shapes/dot-blue.svg">
        </div>
        <div class="shape shape-2">
          <img alt="" src="./assets/images/shapes/dot-green.svg">
        </div>
        <div class="shape shape-4">
          <svg height="329pt" viewBox="0 0 329.26933 329" width="329pt" xmlns="http://www.w3.org/2000/svg"><path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/></svg>
<!--          <img alt="" src="./assets/images/shapes/cross.svg">-->
        </div>
        <div class="shape shape-3">
          <img alt="" src="./assets/images/shapes/dot-pair.svg">
        </div>
      </div>
    </section>
    <section class="about text-center line-up-section">
      <div class="line-up"></div>
      <div class="line-up-content">
        <div class="content">
          <h2 class="section-header">Czym się zajmujemy?</h2>
          <p>Zajmujemy się tworzeniem stron internetowych w Łańcucie, Rzeszowie, jak również na terenie całego kraju. Posiadamy rzetelną wiedzę popartą wypracowanym doświadczeniem zdobytym w pracy nad dużymi projektami.</p>
          <div class="about-wrapper d-flex flex-wrap space-between">
            <div class="card about-item">
              <font-awesome-icon icon="laptop-code" class="icon"/>
              <h3 class="about-item-title">Aplikacje <br>internetowe</h3>
              <p>Tworzymy nowoczesne aplikacje internetowe idealnie dostosowane do Twoich potrzeb</p>
            </div>
            <div class="card about-item">
              <font-awesome-icon icon="desktop" class="icon" />
              <h3 class="about-item-title">Strony <br> internetowe</h3>
              <p>Przygotowujemy profesjonalne strony internetowe z użyciem najnowszych technologii </p>
            </div>
            <div class="card about-item">
              <font-awesome-icon icon="download" class="icon" />
              <h3 class="about-item-title">Scrapowanie <br>danych</h3>
              <p>Dostarczymy dane których potrzebujesz w wygodnej dla Ciebie formie</p>
            </div>
          </div>
        </div>

      </div>
    </section>
    <section class="about-us">
      <div class="content text-center">
        <h2 class="section-header">Strony internetowe to nasza pasja</h2>
        <div class="about-items my-5">
          <div class="about-item">
            <h1 class="underline">
              <count :to="10"></count>+
            </h1>
            <p>lat doświadczenia</p>
          </div>
          <div class="about-item">
            <h1 class="underline"><count :to="99"></count>+</h1>
            <p>zrealizowanych projektów</p>
          </div>
<!--          <div class="about-item">-->
<!--            <h1 class="underline"><count :to="99999"></count>+</h1>-->
<!--            <p>Napisaych linii kodu</p>-->
<!--          </div>-->
          <div class="about-item">
            <h1 class="underline"><count :to="100"></count>%</h1>
            <p>zadowolenia klientów</p>
          </div>
        </div>
      </div>
    </section>

    <section class="technologies text-center bg-light">
      <div class="content">
        <h2 class="section-header">Technologie, z których korzystamy</h2>
        <p>W naszej pracy wykorzystujemy najnowsze i najbardziej sprawdzone technologie, m.in.:</p>
        <!--        <p>Przygotowujemy profesjonalne strony internetowe z wykorzystaniem najnowszych technologii</p>-->
        <!--        <p>W naszej pracy wykorzystujemy najnowsze i najbardziej sprawdzone technologie, które zapewniają bezpieczeństwo i usprawniają korzystanie z naszych produktów.</p>-->
        <div class="tech my-5">
          <div class="card tech-item">
            <img alt="JavaScript" src="./assets/images/tech/js.png"/>
            <p>JavaScript</p>
          </div>
          <div class="card tech-item">
            <img alt="Angular" src="./assets/images/tech/angular.png"/>
            <p>Angular</p>
          </div>
          <div class="card tech-item">
            <img alt="PHP" src="./assets/images/tech/php.png"/>
            <p>PHP</p>
          </div>
          <div class="card tech-item">
            <img alt="Python" src="./assets/images/tech/python.png"/>
            <p>Python</p>
          </div>
          <div class="card tech-item">
            <img alt="Vue" src="./assets/images/tech/vue.png"/>
            <p>Vue.js</p>
          </div>
          <div class="card tech-item">
            <img alt="React" src="./assets/images/tech/react.png"/>
            <p>React</p>
          </div>
          <div class="card tech-item">
            <img alt="Symfony" src="./assets/images/tech/symfony.png"/>
            <p>Symfony</p>
          </div>
          <div class="card tech-item">
            <img alt="Wordpress" src="./assets/images/tech/wordpress.png"/>
            <p>WordPress</p>
          </div>
          <!--          <div class="card tech-item">-->
          <!--            <img alt="Drupal" src="./assets/images/tech/drupal.png"/>-->
          <!--            <p>Drupal</p>-->
          <!--          </div>-->
        </div>
      </div>
    </section>
    <section class="how-to">
      <div class="content text-center">
        <h2 class="section-header">Jak wygląda proces?</h2>
        <p>
          Praca nad przygotowaniem strony internetowej przebiega w kilku etapach:
        </p>
        <div class="process">
          <div class="process-item">
            <h2 class="process-title">
              <span class="process-number">1</span>
              Analiza
            </h2>
            <p class="process-description">
              Pracę rozpoczynamy od analizy potrzeb klienta i jego grupy docelowej tak, aby móc zaproponować najlepsze rozwiązania.
            </p>
          </div>
          <div class="process-item">
            <h2 class="process-title">
              <span class="process-number">2</span>
              Treść
            </h2>
            <p class="process-description">
              Plan serwisu internetowego jest kluczową kwestią, ponieważ o użyteczności strony decyduje funkcjonalność i prostota w korzystaniu.

            </p>
          </div>
          <div class="process-item">
            <h2 class="process-title">
              <span class="process-number">3</span>
              Projekt
            </h2>
            <p class="process-description">
              Etap przygotowania profesjonalnego, funkcjonalnego i intuicyjnego projektu strony WWW dla Twojej firmy.
            </p>
          </div>
          <div class="process-item">
            <h2 class="process-title">
              <span class="process-number">4</span>
              Obsługa
            </h2>
            <p class="process-description">
              Kreowanie wizerunku internetowego marki oraz utrwalenie świadomości produktu u nowych Klientów.
            </p>
          </div>
        </div>
      </div>
    </section>

<!--    <section class="trusted-us bg-light">-->
<!--      <div class="content text-center">-->
<!--        <h2 class="section-header">Referencje</h2>-->
<!--        <p>-->
<!--          Zaufało nam wiele firm, takich jak:-->
<!--        </p>-->
<!--        <div class="logotypes">-->
<!--          <img alt="InfluTool" src="./assets/images/logotypes/influtool.svg"/>-->
<!--          <img alt="Neuromedic" src="./assets/images/logotypes/neuromedic.png"/>-->
<!--          <img alt="SocialAuditor" src="./assets/images/logotypes/socialauditor.svg"/>-->
<!--          <img alt="TurboSpec" src="./assets/images/logotypes/turbospec.svg"/>-->
<!--          <img alt="Minitori" src="./assets/images/logotypes/monitori.png"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <div class="bg-light">
      <ContactForm></ContactForm>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ContactForm from "@/components/ContactForm";
import Count from "./components/Count";

export default {
  name: 'App',
  components: {
    Footer,
    Header,
    ContactForm,
    Count
  }
}
</script>

<style lang="scss">
@import "./styles/styles.scss";


#app {
}

</style>
