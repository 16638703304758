<template>
  <footer>
    <div class="content">
      <div class="footer-content">
        <div>
          <h5 class="footer-header">O nas</h5>
          <h4>ATP Solutions</h4>
          <p class="footer-desc">Dedykowane rozwiązania programistyczne <br>dla Twojego biznesu.</p>
        </div>
        <div class="footer-contact">
          <h5 class="footer-header">Kontakt</h5>
          <p>
            <font-awesome-icon icon="map-marker-alt" class="icon"/>
            Łańcut, Rzeszów
          </p>
          <a href="mailto:kontakt@atpsolutions.pl">
            <font-awesome-icon icon="envelope" class="icon"/>
            kontakt@atpsolutions.pl
          </a>
          <a href="tel:+48782120445">
            <font-awesome-icon icon="phone" class="icon"/>
            782 120 445
          </a>

        </div>
        <div class="footer-social">
          <h5 class="footer-header">Odwiedź nasze profile</h5>
          <a href="https://www.facebook.com/atpsolutionspl">
            <font-awesome-icon :icon="['fab', 'facebook-f']" class="icon" />
          </a>
<!--          <a href="">-->
<!--            <font-awesome-icon :icon="['fab', 'instagram']" class="icon" />-->
<!--          </a>-->
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style lang="scss" scoped>
@import "./../styles/variables.scss";

footer {
  background-color: $bg-dark;
  color: white;
  width: 100%;
  text-align: center;
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .footer-desc {
      font-weight: 200;
    }
    .footer-contact {
      p, a {
        color: white;
        display: block;
        text-decoration: none;
        margin: 15px 0;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
      .icon {
        font-size: 16px;
        margin-right: 5px;
        //color: $text-body;
        color: $green;
        opacity: 0.9;
      }
    }
    .footer-social {
      a {
        padding: 10px;
        .icon {
          font-size: 20px;
          color: white;
          &:hover {
            transform: scale(1.2);

          }
        }
      }
    }
    .footer-header {
      text-transform: uppercase;
      font-weight: 400;
      margin: 20px 0;
      color: $text-light;
    }
    >div {
      flex: 1 1 0px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

</style>
